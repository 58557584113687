<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="nickname" width="100" label="昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="phone" width="100" label="手机号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="area_agent" width="100" :formatter="levelFormat" label="代理级别"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="area_agent_code" label="代理区域" show-overflow-tooltip>
        <template v-slot="s">
          <YFormatAgentArea :value="s.row.area_agent_code"></YFormatAgentArea>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="470" show-overflow-tooltip>
        <template #header>
          <y_choose_member @change="chooseNew">
            <el-button>添加代理</el-button>
          </y_choose_member>
        </template>
        <template v-slot="s">
          <div class="flex-def">
            <YChooseAgentArea @change="chooseArea($event,s.row)" :mode="s.row.area_agent">
              <el-button>修改代理区域</el-button>
            </YChooseAgentArea>
            <el-button style="margin-left: .5rem" @click="clearArea(s.row)">清空代理区域</el-button>
            <el-button style="margin-left: .5rem" @click="editDose(s.row)">调整代理级别</el-button>
            <el-button style="margin-left: .5rem" @click="delDose(s.row)" type="danger">收回权限</el-button>
          </div>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog append-to-body title="选择级别" :visible.sync="editBox" @close="editBoxClose">
      <div>
        <el-form label-width="70px">
          <el-form-item label="用户">
            {{ choose_member.id }}:{{ choose_member.nickname }}
          </el-form-item>
          <el-form-item label="等级">
            <el-radio-group v-model="editForm.level">
              <el-radio :label="1">乡镇</el-radio>
              <el-radio :label="2">区县</el-radio>
              <el-radio :label="3">市</el-radio>
              <el-radio :label="4">省</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>

import y_choose_member from "@/components/shop/y_choose_member";
import YChooseAgentArea from "@/components/shop/YChooseAreaPro/YChooseAgentArea";
import YFormatAgentArea from "@/components/shop/YChooseAreaPro/YFormatAgentArea";

export default {
  components: {YFormatAgentArea, YChooseAgentArea, y_choose_member},
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      editBox: false,
      editForm: {
        uid: 0,
        level: 0,
      },
      choose_member: {},
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    clearArea(item) {
      this.$u.api.shop.plugin.areaAgent.userSetAreaCode({uid: item.id, code: ""}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    chooseArea(e, item) {
      this.$u.api.shop.plugin.areaAgent.userSetAreaCode({uid:item.id,code:e}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    levelFormat(e) {
      let str = "";
      switch (parseInt(e.area_agent)) {
        case 1:
          str = "乡镇";
          break;
        case 2:
          str = "区县";
          break;
        case 3:
          str = "市";
          break;
        case 4:
          str = "省";
          break;
      }
      return str;
    },
    delDose({id}){
      this.editForm = {uid:id,level: 0}
      this.editSubmit();
    },
    editDose(item) {
      this.choose_member = item
      this.editForm.uid = item.id;
      this.editBox = true;
    },
    editBoxClose() {
      this.choose_member = {};
      this.editForm = {
        uid: 0,
        level: 0,
      }
    },
    editSubmit() {
      this.$u.api.shop.plugin.areaAgent.userSetLevel(this.editForm).then(() => {
        this.$message.success("操作成功");
        this.editBox = false;
        this.editBoxClose();
        this.load();
      })
    },
    chooseNew(e) {
      this.choose_member = e;
      this.editForm.uid = e.id;
      this.editBox = true;
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.shop.plugin.areaAgent.userSearch({page: this.page}).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>